// App.js

import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Vazo, Inc.</h1>
        <p>Coming Soon</p>
      </header>
      <footer className="App-footer">
        <p>© 2024 Vazo, Inc. All rights reserved.</p>
        <p>651 N Broad St, Suite 201</p>
        <p>Middletown, DE 19709</p>
      </footer>
    </div>
  );
}

export default App;
